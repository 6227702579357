
import { defineComponent, onMounted } from "vue";
import { trackerSetAction } from "@/utils/vertebraSdk";
import { useHead } from "@vueuse/head";
import { data } from "@/shared/guide";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import DeveloperTopIcon from "@/components/atoms/icon/DeveloperTopIcon/DeveloperTopIcon.vue";
import SubIntroContent from "@/components/templates/SubIntroContent/SubIntroContent.vue";
import GuideBoxContainer from "@/components/templates/GuideBoxContainer/GuideBoxContainer.vue";
import GuideFaq from "@/components/templates/GuideFaq/GuideFaq.vue";
export default defineComponent({
  components: {
    AppLink,
    DeveloperTopIcon,
    SubIntroContent,
    GuideBoxContainer,
    GuideFaq
  },
  setup() {
    useHead({
      title: data.sitemapTitle,
      link: [
        { rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}/guide` }
      ]
    });
    const { title, description, support, sdk, faq, contact } = data;
    onMounted(() => {
      trackerSetAction("개발가이드 페이지");
    });
    return {
      title,
      description,
      support,
      sdk,
      faq,
      contact
    };
  }
});
